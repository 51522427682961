import { dataToSendData } from "@/common/interface_api/check_send.js";
export default function(api){
    api.__createApi(["appVerQuery", "OTA-APP查询"], "/ota/app/ver/query" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["app_id"],
            numKey : ["page_no","page_size"],
        });
        return sendData
    });
    api.__createApi(["addAppVer", "OTA-添加APP"], "/ota/app/ver/add" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["ver", "app_id", "name"],
            numKey : ["force", "state"],
        });
        sendData.details = data.details;
        return sendData
    });
    api.__createApi(["editAppVer", "OTA-修改APP"], "/ota/app/ver/modify" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["id","ver", "app_id", "name"],
            numKey : ["force", "state"],
        });
        sendData.details = data.details;
        return sendData
    });
    api.__createApi(["delAppVer", "OTA-删除APP"], "/ota/app/ver/delete" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["id"],
            numKey : [],
        });
        return sendData
    });
    api.__createApi(["appUrlQuery", "OTA-查询URL"], "/ota/app/url/query" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["app_id"],
            numKey : ["page_no","page_size"],
        });
        return sendData
    });
    api.__createApi(["addAppUrl", "OTA-添加URL"], "/ota/app/url/add" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["app_id", "name"],
            numKey : ["state"],
        });
        sendData.details = data.details;
        return sendData
    });
    api.__createApi(["editAppUrl", "OTA-修改URL"], "/ota/app/url/modify" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["id", "app_id", "name"],
            numKey : ["state"],
        });
        sendData.details = data.details;
        return sendData
    });
    api.__createBearerApi(["getProductVer", "OTA-获取商品版本"],"/v2/ota/admin/product/ver/list", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["pk", "oem", "tag"],
            numKey : ["state"],
        });
        return sendData
    })
    api.__createBearerApi(["addProductVer", "OTA-添加商品版本"],"/v2/ota/admin/product/ver/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["pk", "link_id", "ver", "tag"],
            numKey : ["type", "level", "state", "link_type", "ver_num", "min_ver_num", "max_ver_num", "limit", "count"],
        });
        sendData.details = data.details.map(item => {
            let itemData =  dataToSendData(item, {
                strKey : ["lang", "url", "title", "content", "zone"],
                numKey : ["size", "ver_id"],
                numArrayKey : []
            })
            if(item.id){
                itemData.id = parseInt(item.id)
            }
            if(itemData.size === ""){
                itemData.size = "0"
            }
            return itemData
        })
        return sendData
    })
    api.__createBearerApi(["setProductVer", "OTA-修改商品版本"],"/v2/ota/admin/product/ver/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["pk", "link_id", "ver","oem","batch_id", "tag"],
            numKey : ["id", "type", "level", "state", "link_type", "ver_num", "min_ver_num", "max_ver_num", "limit", "count"],
        });
        sendData.details = data.details.map(item => {
            let itemData =  dataToSendData(item, {
                strKey : ["lang", "url", "title", "content", "zone"],
                numKey : ["size", "ver_id"],
                numArrayKey : []
            })
            if(item.id){
                itemData.id = parseInt(item.id)
            }
            return itemData
        })
        return sendData
    })
    api.__createBearerApi(["deleteProductVer", "OTA-删除商品版本"], "/v2/ota/admin/product/ver/del" ,function(data){
        return dataToSendData(data, {
            strKey : [],
            numKey : ["id"],
        });
    })
}
